import Button from "react-bootstrap/Button";
import styles from "./AuthFooter.module.scss";
import { useTranslation } from "next-i18next";

const AuthFooter = ({
  primaryType,
  isPending,
  handlePrimary = () => {},
  handleSecondary,
  primaryText,
  secondaryText,
  linkText,
  handleLink,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <div
        className={`${styles["container"]} d-flex align-items-center justify-content-between`}
      >
        <Button
          data-qa={"cancel"}
          className={styles.button}
          onClick={handleSecondary}
          variant="secondary"
        >
          {secondaryText}
        </Button>
        <Button
          data-qa={"submit"}
          type={primaryType || "button"}
          onClick={handlePrimary}
          variant="primary"
          className={styles["button"]}
          disabled={isPending}
        >
          {isPending ? t("views.auth.pending") : primaryText}
        </Button>
      </div>
      {linkText ? (
        <Button onClick={handleLink} variant="link">
          {linkText}
        </Button>
      ) : null}
    </>
  );
};

export default AuthFooter;
