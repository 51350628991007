import Image from "next/image";
import LanguageSelector from "../LanguageSelector";
import LoginSelector from "../LoginSelector";
import Navbar from "react-bootstrap/Navbar";
import clsx from "clsx";
import { useRouter } from "next/router";

import { VERSION } from "../../utils/constants";

const DynamicHeader = ({ isWorkerCheckin, selectedLocation = {} }) => {
  const router = useRouter();
  const onChangeLang = (language) => {
    router.push(
      {
        route: router.pathname,
        query: router.query,
      },
      router.asPath,
      { locale: language }
    );
  };

  const onSelectLoginType = (path) => {
    router.push(path);
  };

  return (
    <Navbar
      bg={isWorkerCheckin ? "light" : "dark"}
      variant={isWorkerCheckin ? "light" : "dark"}
      className={"w-100"}
    >
      <Navbar.Brand
        href="/dashboard"
        className={clsx(
          "d-flex align-items-center ms-2",
          !isWorkerCheckin && "d-flex align-items-center me-auto"
        )}
        style={{ height: 32 }}
      >
        <Image
          alt={isWorkerCheckin ? "logo-white" : "logo-white"}
          src={
            isWorkerCheckin
              ? "/logo-blue-horizontal.svg"
              : "/logo-white-vertical.svg"
          }
          width={80}
          height={30}
        />
        <div
          className="h-100 d-flex align-items-center ps-2"
          style={{ fontSize: 12 }}
        >
          {VERSION}
        </div>
      </Navbar.Brand>
      {isWorkerCheckin && (
        <div style={{ width: 188 }} className="ms-auto me-2">
          <p className="mb-0 fw-bold text-center">
            {selectedLocation.label || selectedLocation.name || ""}
          </p>
        </div>
      )}

      <LanguageSelector
        onChange={onChangeLang}
        variant={isWorkerCheckin ? "dark" : "secondary"}
      />
      <LoginSelector
        variant={isWorkerCheckin ? "dark" : "secondary"}
        onChange={onSelectLoginType}
      />
    </Navbar>
  );
};

export default DynamicHeader;
