import "@aws-amplify/ui-react/styles.css";

import { Amplify, Auth, I18n } from "aws-amplify";
import {
  Authenticator,
  translations,
  useAuthenticator,
} from "@aws-amplify/ui-react";

import AuthFooter from "../components/AuthFooter/AuthFooter";
import AuthHeading from "../components/AuthHeading/AuthHeading";
import Button from "react-bootstrap/Button";
import DynamicHeader from "../components/DynamicHeader/DynamicHeader";
import Layout from "../components/Layout";
import Spinner from "react-bootstrap/Spinner";
import awsExports from "../aws-exports";
import clsx from "clsx";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import styles from "../styles/login.module.scss";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";

I18n.putVocabularies(translations);

I18n.putVocabularies({
  en: {
    "Network error": "Network error",
    "Custom auth lambda trigger is not configured for the user pool.":
      "Password cannot be empty",
  },
  es: {
    "Network error": "Error de red",
    "Username/client id combination not found.":
      "No se encontró la combinación de nombre de usuario/ID de cliente.",
    "Password cannot be empty": "La contraseña no puede estar vacía",
    "Confirmation code cannot be empty":
      "El código de confirmación no puede estar vacío",
    "Custom auth lambda trigger is not configured for the user pool.":
      "La contraseña no puede estar vacía",
    "User is disabled.": "Usuario desactivado",
    "User does not exist.": " No existe el usuario",
    "Your passwords must match": "Las contraseñas deben coincidir",
    "Enter your code": "Ingresa el codigo",
    "Incorrect username or password.":
      "Nombre de usuario o contraseña incorrecta",
    "Invalid verification code provided, please try again.":
      "Se proporcionó un código de verificación no válido, inténtelo de nuevo.",
    "Cannot reset password for the user as there is no registered/verified email or phone_number":
      "No se puede restablecer la contraseña para el usuario porque no hay un correo electrónico o número de teléfono registrado/verificado",
  },
});

Amplify.configure({ ...awsExports, ssr: true });

const LoginPage = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  I18n.setLanguage(language);

  const formFields = {
    signIn: {
      username: {
        labelHidden: false,
        placeholder: "",
        label: t("views.auth.email"),
      },
      password: {
        labelHidden: false,
        placeholder: "",
        label: t("views.auth.password"),
      },
    },
    resetPassword: {
      username: {
        labelHidden: false,
        placeholder: "",
        label: t("views.auth.email"),
      },
    },
    confirmResetPassword: {
      confirmation_code: {
        labelHidden: false,
        placeholder: "",
        label: t("views.auth.code"),
        isRequired: false,
      },
      password: {
        labelHidden: false,
        placeholder: "",
        label: t("views.auth.password"),
      },
      confirm_password: {
        labelHidden: false,
        placeholder: "",
        label: t("views.auth.confirmPassword"),
      },
    },
    forceNewPassword: {
      password: {
        labelHidden: false,
        placeholder: "",
        label: t("views.auth.password"),
      },
      confirm_password: {
        labelHidden: false,
        placeholder: "",
        label: t("views.auth.confirmPassword"),
      },
    },
    confirmVerifyUser: {
      confirmation_code: {
        labelHidden: false,
        label: t("views.auth.confirmCode"),
        placeholder: "",
        isRequired: false,
      },
    },
  };

  const services = {
    async handleForgotPassword(formData) {
      // custom username
      return Auth.forgotPassword(formData);
    },
  };

  const components = {
    VerifyUser: {
      Header() {
        return <AuthHeading title={t("views.auth.verifyUserHeading")} />;
      },
      Footer() {
        const { skipVerification, isPending, submitForm } = useAuthenticator(
          (context) => [
            context.skipVerification,
            context.isPending,
            context.submitForm,
          ]
        );
        return (
          <AuthFooter
            handlePrimary={submitForm}
            handleLink={skipVerification}
            isPending={isPending}
            primaryText={t("views.auth.verify")}
            linkText={t("views.auth.skip")}
            secondaryText={t("views.auth.backToLogin")}
          />
        );
      },
    },
    ConfirmVerifyUser: {
      Footer() {
        const { skipVerification, isPending, toSignIn } = useAuthenticator(
          (context) => [
            context.skipVerification,
            context.isPending,
            context.toSignIn,
          ]
        );
        return (
          <AuthFooter
            primaryType="submit"
            handleLink={skipVerification}
            isPending={isPending}
            primaryText={t("views.auth.verify")}
            secondaryText={t("views.auth.backToLogin")}
            handleSecondary={toSignIn}
            linkText={t("views.auth.skip")}
          />
        );
      },
    },
    SignIn: {
      Header() {
        return (
          <AuthHeading
            title={t("views.auth.welcome")}
            textAlign="center"
            padding={`30px 0 20px`}
          />
        );
      },
      Footer() {
        const { toResetPassword, submitForm, isPending } = useAuthenticator(
          (context) => [
            context.toResetPassword,
            context.submitForm,
            context.isPending,
          ]
        );

        return (
          <div
            className={`${styles["container"]} d-flex flex-column align-items-center`}
          >
            <Button
              className={`${styles["reset-password"]} ${styles["link-button"]} align-self-end`}
              variant="link"
              onClick={toResetPassword}
            >
              {" "}
              {t("views.auth.forgotPassword")}
            </Button>
            <Button
              onClick={submitForm}
              className={clsx(styles.button, styles["sign-in"])}
              variant="primary"
              disabled={isPending}
            >
              {isPending ? t("views.auth.pending") : t("views.auth.login")}
            </Button>
          </div>
        );
      },
    },
    ForceNewPassword: {
      FormFields() {
        const { toSignIn, isPending } = useAuthenticator((context) => [
          context.toSignIn,
          context.isPending,
        ]);
        return (
          <>
            <Authenticator.ForceNewPassword.FormFields />
            <AuthFooter
              primaryType="submit"
              handleSecondary={toSignIn}
              isPending={isPending}
              primaryText={t("views.auth.submit")}
              secondaryText={t("views.auth.backToLogin")}
            />
          </>
        );
      },
    },
    ConfirmSignUp: {
      Header() {
        return (
          <AuthHeading
            title={t("views.auth.resetPassword")}
            description={t("views.auth.confirmSignUpDescription")}
          />
        );
      },
      FormFields() {
        return <Authenticator.ConfirmSignUp.FormFields />;
      },
      Footer() {
        const { toSignIn, resendCode, isPending, submitForm } =
          useAuthenticator((context) => [
            context.toSignIn,
            context.isPending,
            context.submitForm,
          ]);

        return (
          <AuthFooter
            linkText={
              isPending ? t("views.auth.pending") : t("views.auth.resendCode")
            }
            handleLink={resendCode}
            handlePrimary={submitForm}
            handleSecondary={toSignIn}
            isPending={isPending}
            primaryText={t("views.auth.submit")}
            secondaryText={t("general.cancel")}
          />
        );
      },
    },
    ResetPassword: {
      Header() {
        return (
          <AuthHeading
            title={t("views.auth.resetPassword")}
            description={t("views.auth.resetPasswordDescription")}
          />
        );
      },
      Footer() {
        const { toSignIn, isPending, submitForm } = useAuthenticator(
          (context) => [context.toSignIn, context.isPending, context.submitForm]
        );
        return (
          <AuthFooter
            handlePrimary={(e) => {
              submitForm(e);
            }}
            handleSecondary={toSignIn}
            isPending={isPending}
            primaryText={t("views.auth.sendCode")}
            secondaryText={t("general.cancel")}
          />
        );
      },
    },
    ConfirmResetPassword: {
      Header() {
        const descriptionComponent = (
          <div>
            <p className="mb-0">{t("views.auth.confirmResetWasSent")}</p>
            <p className="mb-0">{t("views.auth.confirmResetPleaseEnter")}</p>
          </div>
        );
        return (
          <AuthHeading
            title={t("views.auth.resetPassword")}
            textAlign="center"
            description={descriptionComponent}
            padding={"12px 56px 30px"}
          />
        );
      },
      Footer() {
        const { toSignIn, resendCode, isPending, submitForm } =
          useAuthenticator((context) => [
            context.toSignIn,
            context.isPending,
            context.submitForm,
          ]);

        return (
          <AuthFooter
            linkText={
              isPending ? t("views.auth.pending") : t("views.auth.resendCode")
            }
            handleLink={resendCode}
            handlePrimary={submitForm}
            handleSecondary={toSignIn}
            isPending={isPending}
            primaryText={t("views.auth.submit")}
            secondaryText={t("general.cancel")}
          />
        );
      },
    },
  };

  return (
    <Layout
      variant={"dark"}
      customClasses="d-flex flex-column justify-content-start"
    >
      <DynamicHeader isWorkerCheckin={false} />

      <div className="h-100 d-flex justify-content-center">
        <Authenticator
          components={components}
          formFields={formFields}
          hideSignUp={true}
          services={services}
        >
          {() => <AfterLogin />}
        </Authenticator>
      </div>
    </Layout>
  );
};

const AfterLogin = () => {
  // console.log('enviando evento...');
  const router = useRouter();
  router.replace("/dashboard");
  return <Spinner animation="border" />;
};

export async function getServerSideProps(ctx) {
  return {
    props: {
      ...(await serverSideTranslations(ctx.locale, ["common"])),
    },
  };
}

export default LoginPage;
