import { Heading, Text } from "@aws-amplify/ui-react";
import styles from './AuthHeading.module.scss';

const AuthHeading = ({ title, description, textAlign, padding }) => {
  return (
    <div className={styles.container}>
      <Heading textAlign={textAlign} level={3} padding={padding}>
        {title}
      </Heading>   
      {typeof(description) === 'string' ? <Text
        className={styles.description}
        as="p"
        lineHeight="1.5em"
        fontWeight={400}
        fontSize="14px"
        fontStyle="normal"
        textDecoration="none"
      >
        {description}
      </Text>: description } 
     
    </div>
  );
};

export default AuthHeading;
